import * as React from 'react';
import s from './ProductName.scss';
import {classes as productNameStylable} from './ProductName.st.css';
import {IProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';
import classnames from 'classnames';
import {Text} from 'wix-ui-tpa/Text';

export enum DataHook {
  Root = 'product-name',
}

export interface IProductNameProps extends IProvidedGlobalProps {
  name: string;
  bottomGutter?: boolean;
}

class ProductNameComp extends React.Component<IProductNameProps> {
  public static defaultProps: Partial<IProductNameProps> = {
    bottomGutter: false,
  };

  public render(): React.ReactNode {
    const {name, bottomGutter, globals} = this.props;
    const classes = classnames(productNameStylable.root, s.productName, {
      [s.bottomGutter]: bottomGutter,
      [s.horizontalTextAlign]: globals.horizontalLayout,
      [s.verticalTextAlign]: !globals.horizontalLayout,
    });
    return (
      <Text tagName={globals.htmlTags.productNameHtmlTag} data-hook={DataHook.Root} className={classes}>
        {name}
      </Text>
    );
  }
}

export const ProductName = withGlobalProps(ProductNameComp);
